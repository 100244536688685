var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap doc_req_pop",
      staticStyle: { width: "850px", height: "610px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont cod_cont_area" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm.parentInfo.reqCatCd === "16"
            ? _c("span", [_vm._v(" 컨테이너 반출요청 ")])
            : _vm.parentInfo.reqCatCd === "15"
            ? _c("span", [_vm._v(" COD 요청 ")])
            : _vm.parentInfo.reqCatCd === "14"
            ? _c("span", [_vm._v(" SHIP BACK 요청 ")])
            : _vm.parentInfo.reqCatCd === "01"
            ? _c("span", [_vm._v(" 선적 취소 요청 ")])
            : _vm.parentInfo.reqCatCd === "17"
            ? _c("span", [_vm._v(" 컨테이너넘버변경 ")])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("div", { staticClass: "normal_box" }, [
            _vm.parentInfo.reqCatCd === "16"
              ? _c("ol", [
                  _c("li", [
                    _vm._v(
                      "귀하(또는 귀사)는 당사에 컨테이너 반출요청을 하였으며, 이와 관련한 다음 각호의 내용을 확인하시어 동의 확정하여 주시기 바랍니다."
                    ),
                  ]),
                  _c("br"),
                  _c("li", [
                    _vm._v(
                      "1. 귀하(또는 귀사)는 당사에게 컨테이너 반출 및 재입고에 관한 모든 대리권을 수여하였음을 확인합니다. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "2. 해당 반출되는 컨테이너와 관련된 발생 비용 - 상 하차료, Storage Charge, Reefer Monitoring Charge 등 - 에 대해서는 컨테이너 반출 전 선 정산 동의합니다. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "3. 위 컨테이너가 반출된 때로부터 재입고되는 때까지 당사와 귀하(또는 귀사)사와의 운송계약의 효력은 중지되고, 별도 당사에게 운송을 의뢰하지 않은 경우 운송계약은 해제된 것으로 간주됩니다. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "4. 해당 컨테이너 반출 시 컨테이너 상태 점검을 진행하여야 하며, 하자 발견 시 당사에 서면 통지의무가 있습니다. 별도 통지가 없는 경우 해당 컨테이너는 하자없이 인도된 것으로 동의합니다. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "5. 해당 컨테이너 반출에 따른 발생할 수 있는 모든 종류의 책임, 손실, 손상 및 비용에 대해 귀하(또는 귀사)의 책임으로 관련 손해 발생시 배상책임에 동의합니다. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "6. 해당 컨테이너 반출에 따른 화물 운송에 대한 법률 관계는 대한민국 법률에 따르며, 이와 관련된 분쟁 발생하는 경우 대한상사중재원을 전속관할로 하여 해결함에 동의합니다. "
                    ),
                  ]),
                  _c("br"),
                ])
              : _vm.parentInfo.reqCatCd === "14"
              ? _c("ol", [
                  _c("li", [
                    _vm._v(
                      "귀하(또는 귀사)는 당사에 수출 컨테이너에 대해 SHIP BACK 요청을 하였으며, 이와 관련한 다음 각호의 내용을 확인하시어 동의 확정하여 주시기 바랍니다. "
                    ),
                  ]),
                  _c("br"),
                  _c("li", [
                    _vm._v(
                      "1. 해당 SHIP BACK 요청의 전제 조건으로, 당사는 발행된 귀하(또는 귀사)의 O.B/L을 전량 회수함에 동의합니다. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "2. 해당 SHIP BACK 요청에 따른 해상운임 변경 및 추가 비용 발생할 수 있음을 동의합니다. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "3. 해당 SHIP BACK 요청으로 발생할 수 있는 모든 종류의 책임, 손실, 손상 및 비용에 대해 귀하(또는 귀사)의 책임으로 관련 손해 발생시 배상책임에 동의합니다. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "4. 해당 SHIP BACK 요청에 따른 화물 운송에 대한 법률 관계는 대한민국 법률에 따르며, 이와 관련된 분쟁 발생하는 경우 대한상사중재원을 전속관할로 하여 해결함에 동의합니다. "
                    ),
                  ]),
                  _c("br"),
                ])
              : _vm.parentInfo.reqCatCd === "15"
              ? _c("ol", [
                  _c("li", [
                    _vm._v(
                      "귀하(또는 귀사)는 당사에 수출 컨테이너에 대해 도착지 변경 (Change of Destination) 요청을 하였으며, 이와 관련한 다음 각호의 내용을 확인하시어 동의 확정하여 주시기 바랍니다."
                    ),
                  ]),
                  _c("br"),
                  _c("li", [
                    _vm._v(
                      "1. 해당 도착지 변경 (Change of Destination) 요청의 전제 조건으로, 발행된 귀사 O.B/L 전량 회수함에 동의합니다. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "2. 해당 도착지 변경 (Change of Destination)에 따른 해상운임 변경 및 추가 비용 발생할 수 있음을 동의합니다. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "3. 해당 도착지 변경 (Change of Destination)으로 발생할 수 있는 모든 종류의 책임, 손실, 손상 및 비용에 대해 귀하(또는 귀사)의 책임으로 관련 손해 발생시 배상책임에 동의합니다. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "4. 해당 도착지 변경 (Change of Destination)에 따른 화물 운송에 대한 법률 관계는 대한민국 법률에 따르며, 이와 관련된 분쟁 발생하는 경우 대한상사중재원을 전속관할로 하여 해결함에 동의합니다. "
                    ),
                  ]),
                  _c("br"),
                ])
              : _vm.parentInfo.reqCatCd === "01"
              ? _c("ol", [
                  _c("li", [
                    _vm._v(
                      "귀하(또는 귀사)는 당사에 수출 컨테이너 선적취소 요청을 하였으며, 이와 관련한 다음 각호의 내용을 확인하시어 동의 확정하여 주시기 바랍니다. "
                    ),
                  ]),
                  _c("br"),
                  _c("li", [
                    _vm._v(
                      "1. 해당 선적취소 요청에 의해 발생될 수 있는 비용, 손실, 손상에 대해 귀하(또는 귀사)의 배상책임이 있음에 동의합니다."
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "2. 해당 선적취소 컨테이너는 이후 빠른 재선적 진행할 수 있도록 하겠습니다. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "3. 해당 수출 컨테이너 선적취소에 따른 화물 운송에 대한 법률 관계는 대한민국 법률에 따르며, 이와 관련된 분쟁 발생하는 경우 대한상사중재원을 전속관할로 하여 해결함에 동의합니다. "
                    ),
                  ]),
                  _c("br"),
                ])
              : _vm.parentInfo.reqCatCd === "17"
              ? _c("ol", [
                  _c("li", [
                    _vm._v(
                      "귀하(또는 귀사)는 당사에 수출 컨테이너 넘버 변경요청을 하였으며,"
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "이와 관련한 다음 각호의 내용을 확인하시어 동의 확정하여 주시기 바랍니다."
                    ),
                  ]),
                  _c("br"),
                  _c("li", [
                    _vm._v(
                      "1. 해당 수출 컨테이너 넘버 변경요청에 대해 귀하(또는 귀사)는 최종 확인을 하였습니다."
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "2. 해당 수출 컨테이너 넘버 변경요청으로 발생할 수 있는 모든 종류의 책임, 손실, 손상 및 비용에 대해 귀하(또는 귀사)의 책임으로 관련 손해 발생시 배상책임에 동의합니다. "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "3. 해당 수출 컨테이너 넘버 변경요청에 따른 화물 운송에 대한 법률 관계는 대한민국 법률에 따르며, 이와 관련된 분쟁 발생하는 경우 대한상사중재원을 전속관할로 하여 해결함에 동의합니다. "
                    ),
                  ]),
                  _c("br"),
                ])
              : _vm._e(),
          ]),
          _c("p", { staticClass: "mt10" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.agreeYn,
                  expression: "agreeYn",
                },
              ],
              attrs: {
                type: "checkbox",
                id: "agreea1",
                name: "agree",
                "true-value": "Y",
                "false-value": "N",
              },
              domProps: {
                checked: Array.isArray(_vm.agreeYn)
                  ? _vm._i(_vm.agreeYn, null) > -1
                  : _vm._q(_vm.agreeYn, "Y"),
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.agreeYn,
                    $$el = $event.target,
                    $$c = $$el.checked ? "Y" : "N"
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.agreeYn = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.agreeYn = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.agreeYn = $$c
                  }
                },
              },
            }),
            _c(
              "label",
              { staticClass: "color_black", attrs: { for: "agreea1" } },
              [
                _vm._v(
                  "요청 시 주의사항을 충분히 인지하고 요청서를 고려해운으로 발송함."
                ),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "text_center mt10" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goModify()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX060P011.007")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX060P011.008")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }