<template>
  <div class="popup_wrap doc_req_pop" style="width:850px; height:610px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont cod_cont_area">
      <!-- popup_cont -->
      <h1 class="page_title">
        <span v-if="parentInfo.reqCatCd === '16'">
          컨테이너 반출요청
        </span>
        <span v-else-if="parentInfo.reqCatCd === '15'">
          COD 요청
        </span>
        <span v-else-if="parentInfo.reqCatCd === '14'">
          SHIP BACK 요청
        </span>
        <span v-else-if="parentInfo.reqCatCd === '01'">
          선적 취소 요청
        </span>
        <span v-else-if="parentInfo.reqCatCd === '17'">
          컨테이너넘버변경
        </span>
      </h1>
      <div class="content_box">
        <div class="normal_box">
          <ol v-if="parentInfo.reqCatCd === '16'">
            <li>귀하(또는 귀사)는 당사에 컨테이너 반출요청을 하였으며, 이와 관련한 다음 각호의 내용을 확인하시어 동의 확정하여 주시기 바랍니다.</li><br>
            <li>1. 귀하(또는 귀사)는 당사에게 컨테이너 반출 및 재입고에 관한 모든 대리권을 수여하였음을 확인합니다. </li>
            <li>2. 해당 반출되는 컨테이너와 관련된 발생 비용 - 상 하차료, Storage Charge, Reefer Monitoring Charge 등 - 에 대해서는 컨테이너 반출 전 선 정산 동의합니다. </li>
            <li>3. 위 컨테이너가 반출된 때로부터 재입고되는 때까지 당사와 귀하(또는 귀사)사와의 운송계약의 효력은 중지되고, 별도 당사에게 운송을 의뢰하지 않은 경우 운송계약은 해제된 것으로 간주됩니다. </li>
            <li>4. 해당 컨테이너 반출 시 컨테이너 상태 점검을 진행하여야 하며, 하자 발견 시 당사에 서면 통지의무가 있습니다.  별도 통지가 없는 경우 해당 컨테이너는 하자없이 인도된 것으로 동의합니다. </li>
            <li>5. 해당 컨테이너 반출에 따른 발생할 수 있는 모든 종류의 책임, 손실, 손상 및 비용에 대해 귀하(또는 귀사)의 책임으로 관련 손해 발생시 배상책임에 동의합니다. </li>
            <li>6. 해당 컨테이너 반출에 따른 화물 운송에 대한 법률 관계는 대한민국 법률에 따르며, 이와 관련된 분쟁 발생하는 경우 대한상사중재원을 전속관할로 하여 해결함에 동의합니다. </li><br>
          </ol>
          <ol v-else-if="parentInfo.reqCatCd === '14'">
            <li>귀하(또는 귀사)는 당사에 수출 컨테이너에 대해 SHIP BACK 요청을 하였으며, 이와 관련한 다음 각호의 내용을 확인하시어 동의 확정하여 주시기 바랍니다. </li><br>
            <li>1. 해당 SHIP BACK 요청의 전제 조건으로, 당사는 발행된 귀하(또는 귀사)의 O.B/L을 전량 회수함에 동의합니다. </li>
            <li>2. 해당 SHIP BACK 요청에 따른 해상운임 변경 및 추가 비용 발생할 수 있음을 동의합니다. </li>
            <li>3. 해당 SHIP BACK 요청으로 발생할 수 있는 모든 종류의 책임, 손실, 손상 및 비용에 대해 귀하(또는 귀사)의 책임으로 관련 손해 발생시 배상책임에 동의합니다. </li>
            <li>4. 해당 SHIP BACK 요청에 따른 화물 운송에 대한 법률 관계는 대한민국 법률에 따르며, 이와 관련된 분쟁 발생하는 경우 대한상사중재원을 전속관할로 하여 해결함에 동의합니다. </li><br>
          </ol>
          <ol v-else-if="parentInfo.reqCatCd === '15'">
            <li>귀하(또는 귀사)는 당사에 수출 컨테이너에 대해 도착지 변경 (Change of Destination) 요청을 하였으며, 이와 관련한 다음 각호의 내용을 확인하시어 동의 확정하여 주시기 바랍니다.</li><br>
            <li>1. 해당 도착지 변경 (Change of Destination) 요청의 전제 조건으로, 발행된 귀사 O.B/L 전량 회수함에 동의합니다. </li>
            <li>2. 해당 도착지 변경 (Change of Destination)에 따른 해상운임 변경 및 추가 비용 발생할 수 있음을 동의합니다. </li>
            <li>3. 해당 도착지 변경 (Change of Destination)으로 발생할 수 있는 모든 종류의 책임, 손실, 손상 및 비용에 대해 귀하(또는 귀사)의 책임으로 관련 손해 발생시 배상책임에 동의합니다. </li>
            <li>4. 해당 도착지 변경 (Change of Destination)에 따른 화물 운송에 대한 법률 관계는 대한민국 법률에 따르며, 이와 관련된 분쟁 발생하는 경우 대한상사중재원을 전속관할로 하여 해결함에 동의합니다. </li><br>
          </ol>
          <ol v-else-if="parentInfo.reqCatCd === '01'">
            <li>귀하(또는 귀사)는 당사에 수출 컨테이너 선적취소 요청을 하였으며, 이와 관련한 다음 각호의 내용을 확인하시어 동의 확정하여 주시기 바랍니다. </li><br>
            <li>1. 해당 선적취소 요청에 의해 발생될 수 있는 비용, 손실, 손상에 대해 귀하(또는 귀사)의 배상책임이 있음에 동의합니다.</li>
            <li>2. 해당 선적취소 컨테이너는 이후 빠른 재선적 진행할 수 있도록 하겠습니다. </li>
            <li>3. 해당 수출 컨테이너 선적취소에 따른 화물 운송에 대한 법률 관계는 대한민국 법률에 따르며, 이와 관련된 분쟁 발생하는 경우 대한상사중재원을 전속관할로 하여 해결함에 동의합니다. </li><br>
          </ol>
          <ol v-else-if="parentInfo.reqCatCd === '17'">
            <li>귀하(또는 귀사)는 당사에 수출 컨테이너 넘버 변경요청을 하였으며,</li>
            <li>이와 관련한 다음 각호의 내용을 확인하시어 동의 확정하여 주시기 바랍니다.</li><br>
            <li>1. 해당 수출 컨테이너 넘버 변경요청에 대해 귀하(또는 귀사)는 최종 확인을 하였습니다.</li>
            <li>2. 해당 수출 컨테이너 넘버 변경요청으로 발생할 수 있는 모든 종류의 책임, 손실, 손상 및 비용에 대해 귀하(또는 귀사)의 책임으로 관련 손해 발생시 배상책임에 동의합니다. </li>
            <li>3. 해당 수출 컨테이너 넘버 변경요청에 따른 화물 운송에 대한 법률 관계는 대한민국 법률에 따르며, 이와 관련된 분쟁 발생하는 경우 대한상사중재원을 전속관할로 하여 해결함에 동의합니다. </li><br>
          </ol>
        </div>
        <p class="mt10">
          <input type="checkbox" id="agreea1" name="agree" v-model="agreeYn" true-value="Y" false-value="N">
          <label for="agreea1" class="color_black">요청 시 주의사항을 충분히 인지하고 요청서를 고려해운으로 발송함.</label>
        </p>
      </div><!-- content_box // -->
      <div class="text_center mt10">
        <a href="#" class="button blue lg" @click.prevent="goModify()">{{ $t('msg.ONEX060P011.007') }}<!-- 확인 --></a>
        <a href="#" class="button gray lg" @click.prevent="$emit('close')">{{ $t('msg.ONEX060P011.008') }}<!-- 취소 --></a>
      </div>
    </div>
  </div>
</template>
  <script>
  import commons from '@/api/services/commons'
  import transCommon from '@/api/rest/trans/transCommon'
  import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
  import { rootComputed } from '@/store/helpers'

  export default {
    name: 'EleConsReqDocPop',
    components: {
      rootComputed,
      WinLayerPop,
      EDatePicker: () => import('@/components/common/EDatePicker')
    },
    props: {
        parentInfo: {
        type: Object,
        default: function () {
          return {
            blNo: '',
            bkgNo: '',
            reqCatCd: ''
          }
        }
      }
    },
    data () {
      return {
        bookingInfo: {
          type: Object,
          default: null
        },
        agreeYn: 'N'
      }
    },
    computed: {
      ...rootComputed
    },
    created () {
      //this.initPop()
    },
    methods: {
      async initPop () {
        //bkgNo없으면 blNo가져오기 처리
        const parentInfo = this.parentInfo
        if (this.$ekmtcCommon.isEmpty(parentInfo.blNo) && this.$ekmtcCommon.isEmpty(parentInfo.bkgNo)) {
          const TH = this
          this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX070G050.004'), () => {
            TH.$emit('close')
          })
        }
        if (this.$ekmtcCommon.isNotEmpty(parentInfo.bkgNo)) {
          this.init()
        } else {
          const TH = this
          this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX070G050.014'), () => {
            TH.$emit('close')
          })
        }
      },
      async init () {
        const TH = this
        const parentInfo = TH.parentInfo
      },
      goModify () {
      if (this.agreeYn !== 'Y') {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK335') }) // 약관에 동의 하여야 합니다.
        return
      }
      this.$emit('close')
      this.$emit('callback', this.$props.parentInfo)
     }
    }
  }
  </script>
  <style scoped>
  .doc_req_pop {width:910px; height: 800px; overflow-y: scroll;}
  .doc_req_pop > .layer_close {z-index: 10;}
  .div_cntr_area + .div_cntr_area {margin-top: 5px;}
  .text_link {color: #000; padding-left: 2px;}
  .text_link:hover {text-decoration: underline !important;}
  .attdel {cursor: pointer;}
  </style>
